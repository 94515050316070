<template>
  <v-container id="alerts" fluid tag="section">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      loader="dots"
      color="#007bff"
      :height="45"
      :width="75"
      :is-full-page="fullPage"
    ></loading>
    <v-row justify="center">
      <v-dialog
        v-model="toggleFileUploadModel"
        :retain-focus="false"
        max-width="600"
      >
        <template>
          <v-card>
            <v-card-title>
              <span class="headline">Upload File</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-file-input
                      label="File input"
                      outlined
                      dense
                      multiple
                      v-model="file"
                      ref="file"
                    >
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="default" @click="cancelFileUpload"> Cancel </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-row>
    <div class="text-right my-0">
      <v-btn small color="error" dark class="mr-4" @click="deleteFile" v-show="checkIfOperationExistForModule('delete')">
        Delete
      </v-btn>
    </div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <div v-show="checkIfOperationExistForModule('upload')">
            <div id="app" class="app">
              <div class="dropZone">
                <div class="dropZone-info">
                  <input
                    type="file"
                    ref="imageUploader"
                    id="file1"
                    @change="uploadFile"
                    multiple="multiple"
                    @click="resetImageUploader($event, 1)"
                  />
                  <div class="dropZone-upload-limit-info">
                    <span class="dropZone-title"
                      >DROP HERE
                      <v-btn
                        color="primary"
                        size="x-large"
                        @click="resetImageUploader($event, 2)"
                        >UPLOAD NEW FILE</v-btn
                      >
                    </span>
                  </div>
                </div>
              </div>

              <table class="customtable">
                <tr v-for="(f, index) in file" :key="index">
                  <td>
                    <v-progress-circular v-model="f.percent" color="green">
                    </v-progress-circular>
                    <h3 id="status"></h3>
                    <p id="loaded_n_total"></p>
                  </td>
                  <td class="tableWidthMargin">
                    <div class="fileName">{{ f.file.name }}</div>
                    <div>{{ formatFileSize(f.file.size) }}</div>
                  </td>

                  <td>
                    <span v-if="f.percent === 100">
                      <v-icon medium color="green">
                        mdi-checkbox-marked-circle
                      </v-icon>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <br/><br/>
          <div>
            <v-data-table
              v-model="selected"
              :single-select="singleSelect"
              show-select
              :headers="headers"
              item-key="fileID"
              :page.sync="page"
              :items="uploads"
              :options.sync="options"
              :server-items-length="totalUploads"
              :loading="loading"
              class="elevation-1"
              hide-default-footer
              @page-count="pageCount = $event"
            >
              <template v-slot:item.created_date="{ item }">
                <span>{{ formateDate(item.created_date) }}</span>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  title="Download"
                  @click="downloadFile(item.fileID)"
                  v-show="checkIfOperationExistForModule('download')"
                >
                  mdi-cloud-download
                </v-icon>
                <!-- <v-icon
                  small
                  title="Delete File"
                  @click="deleteFile(item.fileID)"
                >
                  mdi-delete
                </v-icon> -->
              </template>
            </v-data-table>
            <!-- <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="10"
      ></v-pagination> -->
            <div></div>
          </div>
          <div class="text-center pt-4 pb-4">
            <Pagination
              :isloading="isLoading"
              :startRecord="startRecord"
              :currentPage="currentPage"
              :lastPage="lastPage"
              :lastRecord="lastRecord"
              :totRecords="totRecords"
              :isCurrentPageClass="isCurrentPageClass"
              :perpage="perpage"
              :getLastPageClass="getLastPageClass"
              :totPage="totPage"
              :getPages="getPages"
              @handlePerPage="handlePerPage"
              @paginate="paginate"
              @last="last"
              @getDataByPage="getDataByPage"
              :showPerPage="showPerPage"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashboardCoreView from "@/views/dashboard/CompanyAutocomplete";
import StatesCoreView from "@/views/dashboard/StateAutocomplete";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import Pagination from "./component/Pagination.vue";
import {
  S3Client,
  PutObjectCommand,
  // GetObjectCommand,
} from "@aws-sdk/client-s3";

export default {
  name: "ManageFileUpload",

  components: {
    DashboardCoreView,
    StatesCoreView,
    Loading,
    Pagination,
  },

  data: () => ({
    //for loading//
    selected: [],
    loading: false,
    isLoading: true,
    fullPage: true,
    ///
    toggleFileUploadModel: false,
    file: [],
    fileLength: 0,
    total_file_size: [],
    //////table data starts here////
    selected: [],
    singleSelect: false,
    options: {},
    sort: "",
    page: 1,
    uploads: [],
    pageCount: 0,
    totalUploads: 0,
    //pagination code
    perpage: 50,
    totRecords: null,
    page: 1,
    isLoading: true,
    updatefile: null,
    fullPage: true,
    showPerPage: false,
    // file: "",
    dragging: false,
    loadingFileProgress: 0,
    filesuploadonS3: false,
    dateNow: null,
    count: 0,
    ///
    // pagination: {
    //   rowsPerPage: 100,
    //   descending: false,
    //    sortable: false,
    //   page: 1
    // },
    headers: [
      {
        text: "Added Date",
        value: "created_date",
        width: "16%",
        sortable: false,
      },
      { text: "Added By", value: "userID", sortable: false, width: "16%" },
      { text: "URL", value: "fileName", width: "16%", sortable: false },
      { text: "File Size", value: "fileSize", sortable: false, width: "16%" },
      { text: "Action", value: "actions", width: "16%", sortable: false },
    ],
    ////// table data ends here
  }),
     

  computed: {
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: function () {
      return this.page;
    },
    lastPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
  },
  watch: {
    "$store.state.Rawdata.current_active_module": function (o) {
      if (o) {
        this.getTempData(this.page);
      }
    },
    page(obj) {
      this.uploads = [];
      this.page = obj;
      //this.getTempData(obj)
    },
  },
  mounted() {
    // this.getTempData(this.pagination.page)
  },

  methods: {
    async resetImageUploader(e, value) {
      if (value === 1 && this.count === 0) {
        e.preventDefault();
      } else if (value === 2) {
        await this.count++;
        document.getElementById("file1").click();
      }
      this.count = 0;
      this.$refs.imageUploader.value = "";
    },
    cancelFileUpload: function () {
      var view = this;
      if(file)
        this.$refs.file.reset();
      view.toggleFileUploadModel = false;
    },
    //pagination methods
    formateDate: function (d) {
      return this.$utils.formateDate(d);
    },
    getDataByPage(value) {
      console.log(value);
      this.page = value;
      this.getTempData(this.page);
    },
    handlePerPage(value) {
      console.log(value);
      this.perpage = value;
      this.getTempData(this.page);
    },
    paginate(n) {
      console.log(n);
      this.page = n === 0 ? 1 : parseInt(this.currentPage) + parseInt(n);
      this.getTempData(this.page);
    },
    last(value) {
      console.log(value);
      this.page = this.lastPage;
      this.getTempData(this.page);
    },
    /////
    saveFileEntries(filename, filesize) {
      var view = this;
      if (this.file) {
        let all_file_uploads_url = this.$url("GETFILEUPLOADS");
        //let all_file_uploads_url = "http://0.0.0.0:5011/file-uploads";
        let filenames = [];

        for (let file of this.file) {
          const postdata = {
            file_name: filename,
            file_size: filesize,
          };
          filenames.push(postdata);
        }
        // postdata["files"] = filenames;
        this.$fetch({
          requiresAuth: true,
          operation: "add",
          vueScope: view,
        })
          .post(all_file_uploads_url, filenames)
          .then((response) => {
            view.isLoading = false;

            // this.$refs.file.reset();
            view.$swal.fire({
              icon: "success",
              title: "Success",
              text: "File Uploaded successfully",
            });
            // view.toggleFileUploadModel = false;
            view.getTempData(view.page);
          })
          .catch((error) => {
            console.log(error);
            view.$swal.fire({
              icon: "error",
              title: "Error",
              text: "Error occured while uploading files",
            });
          });
      }
    },
    gettotalfilesize(e) {
      var view = this;
      view.total_file_size = [];
      console.log("CA", e);
      this.fileLength = e.length;
      for (let file of e) {
        console.log(file.size, "file");
        this.total_file_size.push(parseInt(file.size));
      }
      return this.total_file_size;
    },
    formatFileSize(bytes, decimalPoint) {
      if (bytes == 0) return "0 Bytes";
      var k = 1000,
        dm = decimalPoint || 2,
        sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    async onFileUploaded(event) {
      let view = this;
      const secretAccessKey = this.$store.getters["Rawdata/getS3BucketAccessKeys"].secretAccessKey;
      const accessKeyId = this.$store.getters["Rawdata/getS3BucketAccessKeys"].accessKeyId;
      const bucket = this.$store.getters["Rawdata/getS3BucketAccessKeys"].bucket;
      const region = this.$store.getters["Rawdata/getS3BucketAccessKeys"].region;
      const path = this.$store.getters["Rawdata/getS3BucketAccessKeys"].path;
      const client = new S3Client({
        region,
        credentials: {
          secretAccessKey,
          accessKeyId,
        },
      });
      let tempFile = {
          file:event,
          percent: 70
        }
      view.file.push(tempFile);
      const file = event;
      const systemMetadata = {   
        'Content-Type': event.type, 
      };
      const params = {
        Bucket: bucket,
        Key: `${path + this.dateNow + file.name}`,
        Body: file,
        Metadata: systemMetadata
      };
      const command = new PutObjectCommand(params);

      console.log("command", command);
      let response = null;
      try {
        response = await client.send(command);
        if (response.$metadata.httpStatusCode === 200) {
          // this.isLoading = false;
          for(let i=0; i<view.file.length; i++){
            if(view.file[i].file.name === event.name){
              view.file[i].percent = 100;
            } 
          }
        
          view.filesuploadonS3 = true;
        }
      } catch (err) {
        console.log("error", err);
      }
    },

    async uploadFile(e) {
      console.log(e.target.files[0].type, "esss");
      // console.log("Files.", e.loaded, e.total);
      // (Math.round((100 * data.loaded) / data.total))
      var view = this;
      this.loadingFileProgress = 0;
      // this.file = [];
      view.dateNow = Date.now().toString();
      // view.downloadFilePercent(e);
      let all_uploaded = false;
      let allowed_size = 100000000; //100MB
      let file = e.target.files;
      //check if there is not maximum than allowed no of file
      if (file.length > 10) {
        //prompt error
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: "You are not allowed to upload more than 10 files at once.",
        });
        return;
      }
      //check if size of files not exceed than 100MB or allowed size
      let file_size = this.gettotalfilesize(file);
      console.log("File Size, ", file_size)
      for(let i=0; i<file_size.length; i++){
        if (file_size[i] > allowed_size) {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: "File sizes exceeded than 100MB ",
        });
        return;
      }
      }
      for (let i = 0; i < file.length; i++) {
        await view.onFileUploaded(file[i]);
      }
      // uploading file
      // let all_file_uploads_url = this.$url("UPLOAD_FILE");
      let all_file_uploads_url = this.$url("GETFILEUPLOADS");
      //let all_file_uploads_url = "http://0.0.0.0:5417/fileupload";
      view.isLoading = true;

      let filenames = [];

      for (let file of file) {
        const postdata = {
          file_name: view.dateNow + file.name.toString(),
          file_size: file.size.toString(),
        };
        filenames.push(postdata);
      }
      // let accestoken = this.$utils.getAccessToken();
      // let userId= this.$store.getters['Rawdata/getUserId']
      console.log("then", JSON.stringify(all_file_uploads_url));
      if (view.filesuploadonS3) {
        view.filesuploadonS3 = false;
            this.$fetch({
              requiresAuth: true,
              operation: "add",
              vueScope: view,
            })
              .post(all_file_uploads_url, filenames)
              .then((response) => {
                console.log("Cxs", response);
                view.isLoading = false;

                // this.$refs.file.reset();
                view.$swal.fire({
                  icon: "success",
                  title: "Success",
                  text: "File Uploaded successfully",
                });
                // view.toggleFileUploadModel = false;
                // this.loadingFileProgress = 100;
                this.loadingFileProgress = 100;
                view.getTempData(view.page);
              })
              .catch((error) => {
                console.log(error);
                view.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Error occured while uploading files",
                });
              });

            // view.isLoading = false;
            //all_uploaded = true;
            //this.saveFileEntries();
          };
      view.isLoading = false;
    },
    async downloadFile(fileID) {
      let view = this;
      let download_file_uploads_url =
        this.$url("DOWNLOADFILE") + "?fileID=" + fileID;
      // window.open(download_file_uploads_url);
      window.location.href = download_file_uploads_url;

      // this.$fetch({ requiresAuth: true, operation: "view", vueScope: view })
      //   .get(download_file_uploads_url + "?fileID=" + fileID)
      //   .then((response) => {
      //     view.isLoading = false;
      //     console.log(response.data.payload.url);
      //     if (response.data.statusCode === 200) {
      //       let file_url = response.data.payload.url;
      //       window.open(file_url);
      //     }
      //   });
    },
    deleteFile(fileID) {
      let view = this;
      view.file = [];
      if (view.selected.length == 0) {
        view.$swal.fire("Error", "Please select record!", "error");
        return;
      }
      let filed_ids = [];

      view.selected.forEach((element) => {
        filed_ids.push(element.fileID);
      });
      let file_id_str = filed_ids.join();

      let file_uploads_url = this.$url("GETFILEUPLOADS");
      view.$swal
        .fire({
          title: "Are you sure ?",
          text: "This will delete this file",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Yes, delete it !",
          cancelButtonText: "No, cancel !",
          closeOnConfirm: false,
          closeOnCancel: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            view.isLoading = true;
            this.$fetch({
              requiresAuth: true,
              operation: "delete",
              vueScope: view,
            })
              .delete(file_uploads_url + "?fileID=" + file_id_str)
              .then((response) => {
                view.isLoading = false;
                view.selected = [];
                view.getTempData(view.page);
                //view.getTempDta(view.page);
                if (response.status == 200) {
                  console.log(response.data.payload.message);

                  view.$swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "file deleted successfully",
                  });
                  filed_ids = [];
                }
              });
          } else if (result.isDenied) {
            view.$swal.fire("Cancelled", "File was not deleted!", "error");
          }
        });
    },
    openFileUploadModel() {
      this.toggleFileUploadModel = true;
      this.$refs.file.reset();
    },
    getTempData(page) {
      let view = this;
      view.isLoading = true;
      let all_file_uploads_url = this.$url("GETFILEUPLOADS");

      this.$fetch({ requiresAuth: true, operation: "view", vueScope: view })
        .get(all_file_uploads_url + "?page=" + page)
        .then((response) => {
          view.isLoading = false;
          console.log(response.data.payload);
          view.uploads = response.data.payload.uploads_data;
          view.totalUploads = response.data.payload.count;
          view.totRecords = response.data.payload.count;
          view.pageCount = parseInt(response.data.payload.total_pages);
        });
    },
    checkIfOperationExistForModule(type) {
      return this.$utils.checkIfOperationExistForModule(this, type);
    },
  },
};
</script>

<style>
.dropZone {
  height: 200px;
  position: relative;
  border: 2px dashed grey;
}

.dropZone:hover {
  border: 2px solid #0000FF;
}
.dropZone-title {
  color: #fff;
}

.dropZone-info {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  height: 198px;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #5c5c5c;
  opacity: 0.8;
}
.customtable {
  border-collapse: collapse;
  width: 100%;
}
.customtable td {
  padding: 15px;
  text-align: left;
  vertical-align: middle;
  font-weight: 300;
  font-size: 12px;
  height: 70px;
  color: #fff;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}
.tableWidthMargin {
  width: 90%;
}
.fileName {
  font-size: 21px;
  font-weight: 600;
}

.app {
  width: 100%;
  background-color: black;
}
</style>
